import * as React from "react"
import { SignUpContainer } from "./index.styles"
import { Form, Input, Divider } from "semantic-ui-react"
import { validateForm } from "./Validator"
import { H4 } from "@styles/Global.styles"
import { KpButton } from "@elements/index"
import theme from "@styles/theme"
import { ButtonTypes } from "@utils/Constant"
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types"
import {
  AuthFormLabel,
  AuthSignupLink,
  AuthSignupMessage,
  ErrorMessageContainer,
} from "@styles/Global.forms"
import { FcGoogle } from "react-icons/fc"
import { FaFacebookF } from "react-icons/fa"
import { Link } from "gatsby"
import { Auth } from "aws-amplify"

type SignupSuccessType = {
  success: boolean
  medium: string
  destination: string
}

const initialSignupSuccess: SignupSuccessType = {
  success: false,
  medium: "",
  destination: "",
}

const SignUpForm: React.FC<{}> = () => {
  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [signupSuccess, setSignupSuccess] = React.useState<SignupSuccessType>(
    initialSignupSuccess
  )
  const [resent, setResent] = React.useState(false)
  const [error, setError] = React.useState({
    name: "",
    message: "",
  })

  async function signUp(email: string, password: string, name: string) {
    setLoading(true)
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name,
      },
    })
      .then(res => {
        console.log(res)
        const medium = res.codeDeliveryDetails.AttributeName
        const dest = res.codeDeliveryDetails.Destination
        setSignupSuccess({ success: true, medium: medium, destination: dest })
        setLoading(false)
      })
      .catch(err => {
        setSignupSuccess({ ...initialSignupSuccess, success: false })
        setError(err)
        setLoading(false)
      })
  }

  const resend = () => {
    setResent(false)
    Auth.resendSignUp(email)
      .then(res => {
        console.log(res)
        setResent(true)
      })
      .catch(err => {
        console.log(err)
        setResent(false)
      })
  }

  return (
    <SignUpContainer>
      {signupSuccess.success ? (
        <div>
          <H4 textAlign="center" style={{ paddingBottom: "20px" }}>
            Email Verification
          </H4>
          {resent && <div>Verification Email has been resend!</div>}
          <div>
            A verification link has been sent to your {signupSuccess.medium}{" "}
            {signupSuccess.destination}, please click the link to verify your account and <Link to="/login/">Login</Link>. 
            <br></br> If you haven't received the email,
            {" "}
            <a onClick={resend} href="#">
              click here
            </a>{" "}
            to re-send it.
          </div>
        </div>
      ) : (
        <div>
          <H4 textAlign="center" style={{ paddingBottom: "20px" }}>
            Sign Up
          </H4>
          <Form
            onSubmit={e => {
              e.preventDefault()
              if (validateForm(name, email, password, setError)) {
                signUp(email, password, name)
              }
            }}
          >
            <Form.Field>
              <AuthFormLabel>NAME</AuthFormLabel>
              <Input
                type="text"
                name="name"
                value={name}
                id="name"
                placeholder="Full Name"
                onChange={e => setName(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <AuthFormLabel>EMAIL</AuthFormLabel>
              <Input
                type="email"
                name="email"
                value={email}
                id="email"
                placeholder="Email address"
                onChange={e => setEmail(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <AuthFormLabel>PASSWORD</AuthFormLabel>
              <Input
                type="password"
                name="password"
                value={password}
                id="password"
                onChange={e => setPassword(e.target.value)}
              />
            </Form.Field>
            {/* <Form.Field>
          <AuthFormLabel>Confirm Password</AuthFormLabel>
          <Input
            type="password"
            name="passwordRetype"
            value={passwordRetype}
            id="passwordRetype"
            onChange={e => setPasswordRetype(e.target.value)}
          />
        </Form.Field> */}
            <Form.Field
              style={{ textAlign: "center", padding: "10px 0 10px 0" }}
            >
              <KpButton
                id="signup-submit"
                color={theme.brand.colors.green}
                buttonType={ButtonTypes.Primary}
                fullWidth="mobile tablet computer"
                type="submit"
              >
                {loading ? "Loading..." : "Create account"}
              </KpButton>
            </Form.Field>
            {error.message &&
              (error.name == "UsernameExistsException" ? (
                <ErrorMessageContainer>
                  {error.message} Please <Link to="/login">Login</Link> or{" "}
                  <Link to="/forgot-password">Reset Password.</Link>
                </ErrorMessageContainer>
              ) : (
                <ErrorMessageContainer>{error.message}</ErrorMessageContainer>
              ))}
          </Form>

          <Divider horizontal style={{ color: "#666" }}>
            Or
          </Divider>
          <div style={{ textAlign: "center", paddingTop: "15px" }}>
            <KpButton
              id="signin-submit"
              onClick={() => {
                Auth.federatedSignIn({
                  provider: CognitoHostedUIIdentityProvider.Google,
                })
              }}
              color={theme.brand.colors.darkGrey}
              buttonType={ButtonTypes.Secondary}
              fullWidth="mobile tablet computer"
              textColor={theme.brand.colors.black}
              type="submit"
            >
              <div style={{ width: "100%", paddingLeft: "15px" }}>
                <FcGoogle size={24} style={{ float: "left" }} />
                <span style={{ lineHeight: "24px" }}>sign up with Google</span>
              </div>
            </KpButton>
          </div>
          <div style={{ textAlign: "center", paddingTop: "15px" }}>
            <KpButton
              id="signin-submit"
              onClick={() => {
                Auth.federatedSignIn({
                  provider: CognitoHostedUIIdentityProvider.Facebook,
                })
              }}
              color={theme.brand.colors.darkGrey}
              buttonType={ButtonTypes.Secondary}
              fullWidth="mobile tablet computer"
              type="submit"
            >
              <div style={{ width: "100%", paddingLeft: "15px" }}>
                <FaFacebookF
                  size={24}
                  style={{ float: "left", color: "#1877f2" }}
                />{" "}
                <span style={{ lineHeight: "24px" }}>
                  sign up with Facebook
                </span>
              </div>
            </KpButton>
          </div>
          <div style={{ textAlign: "center", paddingTop: "10px" }}>
            <AuthSignupMessage>Alreadyhave an account?</AuthSignupMessage>
            <AuthSignupLink to="/login">Login now!</AuthSignupLink>
          </div>
        </div>
      )}
    </SignUpContainer>
  )
}
export default SignUpForm
