import * as React from "react"
import { AuthPageWrapper, SignUpWrapper } from "./index.styles"
import SignUpForm from "./SignupForm"
/** Context consumer */
/** Presentation/UI */
const Signup = () => {
  return (
    <AuthPageWrapper>
      <SignUpWrapper>
        <SignUpForm />
      </SignUpWrapper>
    </AuthPageWrapper>
  )
}
export default Signup
