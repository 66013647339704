import React from "react"
import { Layout } from "@components/Layout"
import Signup from "@auth/Signup"

import { SEO } from "@components/SEO"

const SignupPage = () => (
  <Layout version="blank" authEnabled={true}>
    <SEO title="Sign Up" />
    <Signup />
  </Layout>
)

export default SignupPage
